body, html {
  overflow-x: hidden;
  margin: 0;
  width: 100%;
  font-family: 'Times New Roman', Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000013;
  /* color: #f1f1f6; */
  font-weight: 800;
  /* font-style: italic; */
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  /* background-color: rgb(227, 232, 237); */
  /* background-color: aqua; */

  background-image: url('https://www.ciencia.gob.es/.imaging/default/dam/WEB/No-rastreable/Im-genes-espec-ficas/ESTRATEGIAS-Y-PLANES/GOBERNANZA-ESTRATEGICA/SICTI/SICTI-Banner-N2.jpg/jcr:content.jpg');

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; 
  /* text-shadow: 2px 2px 4px #000000;  */
  
}
@media screen and (max-width: 768px) {
  .contact img {
    display: none;
  }
  #banner{
    top: 320px;
  }
  
  #bscroll{
    display: none;
  }

}
.superior .b img  {
  padding-top: 50px;
  width: 250px;
  height: 200px;
  border-radius: 20px;
}

* {
  box-sizing: border-box; /* Esto asegura que los márgenes y bordes no se sumen al ancho y alto de los elementos */
}


.html {
  /* background-color: rgb(255, 254, 254);*/
  /* background-color: aqua; */

  height: 100px;
  width: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bottomBanner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 500px;
  width: 100%;
  color: black;
  background: linear-gradient(to bottom, rgb(400 800 400 / 100%), rgb(163 236 181 / 76%)), url(https://www.fundacionaquae.org/wp-content/uploads/2020/02/propiedades-agua.jpg);
  background-size: cover;
  background-repeat: no-repeat; 
  background-position: center; 
}

.bottomSuperior img{
  width: 250px;
  height: 200px;
  border-radius: 20px;

}

.bottomSuperior{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 500px;
  margin-top: 50px;
  align-items: flex-start;
}

.superiorIzq{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 100px;
}

.superiorIzq a{
font-size: xx-large;
text-decoration: none;
font-family: sans-serif;
color: rgb(20, 20, 20);
font-family: Arial, Helvetica, sans-serif;
}

.superiorIzq a:hover{
  color:rgb(90, 85, 85);

}

.superiorDerecha{
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  margin-right: 200px;
  width: 300px;
  font-family: sans-serif;
  color: black;
}
.superiorDerecha button{
  margin-top: 20px;
  width: 50%;
  color: rgb(249, 247, 247);
  background-color: rgb(14, 13, 13);
  border: none;
}

.superiorDerecha h2{
  margin-top: 0px;
}

@media screen and (max-width: 768px) {
  .superiorIzq {
    display: none;
  }
  .bottomBanner{
    /* justify-content: center; */
    align-items: center;

  }
  .bottomSuperior{
    width: 100%;
    /* align-items: ; */
    justify-content: space-evenly;
    margin-top: 20px;
    margin-left: 10rem;
  }
}

@media screen and (max-width: 768px) {
.contact{
  width: 100vw;
  margin-top: 270px;
}

.bottomBanner{
  width: 100vw;
}
}
