.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1100;
    transition: 0.5s;
  }
  
  .popup {
    display: flex;
    flex-direction: column;
    background: white;
    opacity: 1;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    align-items: center;
    max-width: 90%; 
    max-height: 80%; 
    overflow-y: auto;  
    overflow-x: hidden;
    z-index: 1101;
  }
  .popup h2 {
    color: #000;
    font-size: 1.5rem; /* Asegurarse de que el texto sea legible */
  }
  .popup p {
    color: #000;
    font-size: 1rem; /* Asegurar tamaño adecuado */
    margin-bottom: 20px;
  }
  
  .text{
    width: 800px;
    width: 95%;
  }

  .popup button {
    margin-top: 10px;
    padding: 8px 15px;
    background: red;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }

  .cerrarTop{
    transition: 0.5s;
  }
  .positioncT{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .cerrarTop:hover{
    color: black;
    transition: 0.5s;
  }
  
  .popup button:hover {
    background: darkred;
  }

  
  
  @media screen and (max-width: 768px) {
    .text h2{
      width: 80%;
        font-size: large;
    }
    .text{
        width: 80%;
        font-size: 12px;
    }
  }