/* Estilo para el dropdown */
.dropdown {
    display: none;
    position: relative;
    /* background-color: aqua; */
    position: 0;
  }

  @media screen and (max-width: 768px) {
    .dropdown{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.dropdown button, svg{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    padding: 8px;
    width: 70px;
    height: 60px;
    /* background-color: aqua; */
}
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #49a3df;
    min-width: 160px;
    box-shadow: 0px 2px 20px 3px rgb(29 39 224 / 50%);
    z-index: 1001;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
    transition: 0.5s;
  }
  
  .dropdown-content a {
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    transition: 0.4s;
  }
  
  .dropdown-content a:hover {
    background-color: #ddd;
    color: black;
    transition: 0.5s;
  }

