.carousel-container {
    overflow: hidden;
    display: flex;
    width: 600px; /* Ajusta al tamaño de tus imágenes */
  }
  
  .carousel {
    display: flex;
    transition: transform 1s ease-in-out;
  }
  
  .carousel img {
    width: 300px;
    height: auto;
  }
  
  button {
    margin: 10px;
    padding: 10px 15px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
/* aqui arriba es chatgpt abajo es mio */
.botones {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 15%;
    background-color: black;
}

/* body{
    display: flex;
    justify-content: center;
    margin: 0;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: aquamarine;
} */

.caja1{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.caja {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding:30px;
    align-items: center;
    width: 800px;
    height: 60%;
    /* background-color: black; */
    color: azure;
}
@media screen and (max-width: 768px) {
    .caja{
        width: 50%;
        align-items: center;
    }
    .carousel-container{
        width: 300px;
    }
    .carousel img {
        width: 150px;
        height: auto;
      }
  }

.img12{
    display: flex;
    height: 120px;
}

.dosImagenes{
    display: flex;
    flex-direction: row;

}

#contenido2, .img34{
    display: none;
    height: 120px;
    /* visibility: hidden; */
}
