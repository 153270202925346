
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* #root{
  background-color: aqua;
} */



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* de aqui a abajo es mio */
.superior{
display: flex;
align-items: center;
width: 100vw;
flex-direction: row;
justify-content: space-between;
position: relative;
height: 150px;
}

.superior .b {
  padding-left: 80px;
}

#number{
  text-decoration: none;
  font-size: 3rem;
  transition: 0.5s;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: #49a3df;
}
#number:hover{
  color: black;
}


.call{
  display: flex;
  padding-right: 70px;
  flex-direction: column;
  justify-content: center;
  /* margin-right: 10px; */
  align-items: center;
  color: rgb(7, 7, 7);
  text-shadow: none;
  /* padding: 10px; */
}

.call .textC{
  font-size: large;
}


@media screen and (max-width: 768px) {
  .superior {
    flex-direction: column;
    justify-content: flex-start;
  }
  .superior .b {
    padding-left: 0;
  }
  .superior .call .textC {
    font-size: medium;
    padding-right: 0;
  }
  .superior .call  {
    padding-right: 0;
  }
  .superior .call .number {
    font-size: 1.5rem;
  }

  /* .bottomBanner   {
    padding: 0px;
    width: 150px;
    height: 65px;
  } */

  .superior .b a {
    padding: 0px;
    width: 5px;
    height: 5px;
  }
}


.center{
  display: flex;
  margin-top: 120px;
  flex-direction: column;
  height: 500px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  }
  
  /* .top-banner img {
    height: 100px;
    width: 130px;
    margin: 0;
  } */

#bscroll{
  display: flex;
  flex-direction: row;
}

  .top-banner .b{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
  }
  /* pruebas banner que cambia */

  .scrolled {
    top: 0; /* Cambia la posición cuando se hace scroll */
  }


  .contact{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* height: 375px; */
    width: 100%;
    margin-top: 140px;
    background: linear-gradient(to bottom, rgb(52, 202, 244), rgba(0, 50, 255, 0));
  }



  .textoC{
    display: flex;
    flex-direction: column;
    width: 22rem;
    font-size: 1.3rem;
    justify-content: center;
    /* align-items: center; */
    color: black;
    text-shadow: none;
    margin-top: 25px;
    /* margin-top: 20px; */
    font-weight: 500;
  }

  .textoC .button {
    display: flex;
    justify-content: center;
    color: rgb(5, 5, 5);
    font-size: 1.5rem;
    text-decoration-line: none;
    font-weight: 700;
    cursor: pointer;
    border: none;
    border-radius: 100px;
    padding: 20px;
    background-color: white;
    margin-top: 2rem;
    transition: 0.5s;
  }

  .button:hover{
    background-color: rgb(48, 49, 49);
    transition: 0.5s;
    color: white;
  }

  .contact img{
    display: flex;
    margin-top: 20px;
    width: 25rem;
    height: 18rem;
    border-radius: 30px;
  }

  .Links{
    font-size: xx-large;
    text-decoration-line: none;
    transition: opacity 0.3s ease; 
    width: 200px;
    padding: 10px;
    transition: 0.5s;
  }
/* este .top-banner esta demas creo pero igual lo dejamos (el #banner es el que aplica y tiene lo mismo */
  .top-banner{
    /* height: 100px;
    display: flex;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    flex-direction: row; */
    justify-content: space-between;
    width: 100%;
    /* position: fixed; */
  }

  #banner {
    /* width: 100%; */
    background-color: #49a3df;
    /* color: rgb(226, 49, 49); */
    color: rgb(255, 255, 255);
    text-align: center;
    /* padding: 20px; */
    position: absolute;  /* Inicialmente, no es fijo */
    top: 190px; 
    left: 50%; /* Centra horizontalmente */
    transform: translateX(-50%);
      z-index: 1000;
    transition: top ; /* Transición para el cambio de posición */
    height: 100px;
    display: flex;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    flex-direction: row;
    justify-content: space-evenly;
    /* background-color: rgba(106, 99, 99, 0.4); */
    /* border-radius: 50%; */
    box-shadow: 0px 2px 20px 3px rgb(29 39 224 / 50%);
    position: fixed;
  }

.Links:hover{
  background-color: rgb(34, 33, 33);
  transition: 0.5s ;
}

.renovationForm {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  height: 550px;
  justify-content: space-around;
  width: 100%;
  /* gap: 20px; */
  /* margin-left: 30px; */
}
.renovationInfo{
display: flex;
flex-wrap: wrap;
flex-direction: column;
font-size: large;
width: 50%;
padding-left: 100px;
justify-content: baseline;
align-items: baseline;
}
.renovationInfo ul{
font-size: x-large;
  }

.textoD{
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: x-large;
  color: black;
  text-shadow: none;
  font-weight: 500;
  justify-content: baseline;
  align-items: baseline;
}

.textoD li{
  font-size: large;

}
